import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import VideoEmbed from '../book-new-call/VideoEmbed';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';
import useHotjarUser from '../../hooks/useHotjarUser';

const WhichAppointmentReferral = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  useHotjarUser(undefined, {
    email: customerData.email,
    firstName: customerData.firstName,
  });

  const decodedOrderId = decodeURIComponent(orderId);

  useEffect(() => {
    document.body.style.backgroundColor = 'white';

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.pompaprogram.com/get-samcart-customer?order_id=${decodedOrderId}`
        );
        const { first_name, last_name, email, phone } = response.data.customer;
        setCustomerData({
          firstName: first_name,
          lastName: last_name,
          email,
          phone,
        });
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };

    fetchData();
    return () => {
      document.body.style.backgroundColor = '#e0f2fe';
    };
  }, []);

  const buildQueryString = (params) => {
    return Object.keys(params)
      .filter((key) => params[key] !== null && params[key] !== '') // Filter out null or empty values
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      ) // Encode and join
      .join('&'); // Join all parameters with '&'
  };

  const goToAppointment = () => {
    const params = {
      Name: customerData.firstName,
      lastName: customerData.lastName,
      mobile: customerData.phone,
      email: customerData.email,
    };
    const queryString = buildQueryString(params);
    window.location.href = `/time2book-referral?${queryString}`;
  };

  return (
    <BookStepsLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#efe9e5',
          height: '220px',
          textAlign: 'center',
          '@media (max-width: 700px)': {
            height: 'auto',
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            fontWeight: '700',
            marginBottom: '20px',
            marginTop: '50px',
            '@media (max-width: 700px)': {
              fontSize: '28px',
              marginTop: '20px',
            },
          }}
        >
          WAIT! YOUR ORDER IS NOT COMPLETE.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '30px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '1150px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 700px)': {
              width: '90%',
            },
          }}
        >
          <PompaProgressBar width="55%" message="Almost Complete..." />
        </Typography>
      </Box>
      <Box
        sx={{
          fontSize: '32px',
          fontWeight: '700',
          textAlign: 'center',
          marginTop: '30px',
          marginBottom: '20px',
          '@media (max-width: 700px)': {
            fontSize: '22px',
          },
        }}
      >
        {' '}
        Now you need to book your cellular analysis appointment.
      </Box>
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '30px',
          paddingTop: '20px',
        }}
      >
        <VideoEmbed />
        <Box mb={2} />
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <Button
          sx={{
            mb: 2,
            width: '307px',
            borderRadius: '3px',
            backgroundColor: '#e7313d',
            fontSize: '17px',
            boxShadow: 'inset 0 1px 0 rgba(255,255,255,0.2)',
            border: '1px solid rgba(0,0,0,0.2)',
            textTransform: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px',
            '&:hover': {
              backgroundColor: '#e7313d',
              boxShadow:
                'inset 0 2px 2px 0 #ffffff38, 0 233px 233px 0 rgba(255,255,255,0.12) inset',
            },
          }}
          onClick={goToAppointment}
          type="button"
          variant="contained"
        >
          <Typography
            variant="body1"
            component="span"
            style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}
          >
            Book Appointment
          </Typography>
          <Typography
            variant="caption"
            component="span"
            style={{ color: 'white', fontSize: '14px', opacity: '0.7' }}
          >
            For those that purchased a single test kit
          </Typography>
        </Button>
      </Box>
    </BookStepsLayout>
  );
};

export default WhichAppointmentReferral;
