import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Loader from '../loader/Loader';
import Logo from '../../app/common/Logo';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  setUserSessionEmail,
  setUserData,
} from '../../app/services/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getApiKey, getUrl } from '../../app/api/agent';
import { Alert } from '@mui/material';
import DarkLogo from '../../app/common/DarkLogo';

export default function AutoLogin() {
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    const redirectTo = searchParams.get('redirectTo')
      ? searchParams.get('redirectTo')
      : '';

    if (token) {
      loginWithToken(token, redirectTo);
    }
  }, [searchParams]);

  const loginWithToken = (token, redirectTo) => {
    const config = {
      url: getUrl() + '/login-with-token',
      method: 'post',
      data: {
        token: token,
      },
      headers: {
        'x-api-key': getApiKey(),
        'Content-Type': 'application/json',
      },
    };

    axios(config)
      .then((response) => {
        setUserSessionEmail(response.data.user.email, response.data.token);
        setUserData(response.data.user);
        setTimeout(() => {
          navigate('/' + redirectTo || '/');
        }, 3000);
      })
      .catch((error) => {
        console.error('Error setting stage:', error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setErrorMessage(error.response.data.message);
        } else if (error.message) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(
            'An unexpected error occurred, please try again later'
          );
        }
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        backgroundColor: 'background.paper',
        padding: 5,
        marginTop: 5,
      }}
    >
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DarkLogo />
        <Typography component="h1" variant="h5">
          Logging you in
        </Typography>
        <Typography component="h2" variant="h5">
          please stand by...
        </Typography>
        <Loader />
      </Box>
      <div style={{ marginTop: '10px' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </div>
    </Container>
  );
}
