import * as Sentry from '@sentry/react';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

const getEnvironmentConfig = () => {
  // You can expand this based on your deployment setup
  const env = process.env.REACT_APP_ENVIRONMENT || 'development';

  if (env === 'prod') {
    return {
      environment: 'production',
      tracesSampleRate: 0.1,
      debug: false,
    };
  }

  if (env === 'staging') {
    return {
      environment: 'staging',
      tracesSampleRate: 0.5,
      debug: true,
    };
  }

  return {
    environment: 'development',
    tracesSampleRate: 1.0,
    debug: true,
  };
};

export const initializeSentry = () => {
  if (!SENTRY_DSN) {
    console.warn('Sentry DSN not found. Error tracking is disabled.');
    return;
  }

  const envConfig = getEnvironmentConfig();

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Apply environment-specific settings
    ...envConfig,
  });
};

// Optional: Add custom context information
export const setUserContext = (user) => {
  Sentry.setUser({
    email: user.email,
  });
};
