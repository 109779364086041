import { useEffect } from 'react';

const useHotjarUser = (userId, userData = {}) => {
  useEffect(() => {
    console.log('hotjar info:', {
      userId,
      userData,
    });
    if (typeof window !== 'undefined' && window.hj && userData.email) {
      window.hj('identify', userData.email, {
        ...userData,
        userId: userId,
      });
      console.log('hotjar user identified');
    }
  }, [userId, userData]);
};

export default useHotjarUser;
