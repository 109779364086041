import React from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const TokenExpired = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e0f2fe',
    }}
  >
    <Box
      sx={{
        maxWidth: 500,
        mx: 'auto',
        p: 4,
        textAlign: 'center',
        borderRadius: 2,
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        backgroundColor: 'background.paper',
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        Token Expired
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Your token has expired. To continue, please request a new token.
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
        Contact us at{' '}
        <Link href="mailto:support@pompaprogram.com" underline="hover">
          support@pompaprogram.com
        </Link>
      </Typography>
      <Button variant="contained" color="primary" sx={{ mt: 3 }} href="/">
        Go Home
      </Button>
    </Box>
  </Box>
);

export default TokenExpired;
